import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node';

import { cssBundleHref } from '@remix-run/css-bundle';
import { LinksFunction } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useLoaderData,
  useLocation,
} from '@remix-run/react';
import { withSentry } from '@sentry/remix';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useChangeLanguage } from 'remix-i18next/react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';

import faviconAssetUrl from '~/assets/logo-colour.png';
import { GeneralErrorBoundary } from '~/components/ErrorBoundary';
import * as gtag from '~/lib/gtags.client';
import { honeypot } from '~/lib/honeypot.server';
import { i18next } from '~/lib/i18n/server';
import { wordpress } from '~/services/wordpress';
import fontCss from '~/styles/font.css?url';
import tailwindCss from '~/styles/tailwind.css?url';
import wordpressCss from '~/styles/wordpress.css?url';
import { cookies } from '~/utils/cookies';

import { Toaster } from './components/ui/toaster';

export const links: LinksFunction = () => [
  { rel: 'icon', type: 'image/png', href: faviconAssetUrl, as: 'image' },
  { rel: 'stylesheet', href: fontCss, as: 'style' },
  { rel: 'stylesheet', href: wordpressCss, as: 'style' },
  { rel: 'stylesheet', href: tailwindCss, as: 'style' },
  ...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref, as: 'style' }] : []),
];

export async function loader({ request }: LoaderFunctionArgs) {
  const honeyProps = honeypot.getInputProps();
  const locale = (await i18next.getLocale(request)) ?? 'en';
  const gaTrackingId = process.env.GA_TRACKING_ID;
  const { settings } = await wordpress.GetSettings({ language: locale });
  const { languages } = await wordpress.GetLanguages();
  const title = settings.title ?? 'STARRT';
  const description =
    settings.description ?? 'Stroke Toolkit for Aquatic Rehabilitation and Recreation Therapy';

  return json(
    {
      honeyProps,
      description,
      languages,
      gaTrackingId,
      locale,
      title,
    },
    { headers: { 'Set-Cookie': await cookies.i18n.serialize(locale) } }
  );
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [{ title: data?.title }, { description: data?.description }];
};

export function Layout({ children }: { children: React.ReactNode }) {
  const { locale, honeyProps, gaTrackingId } = useLoaderData<typeof loader>();
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (gaTrackingId?.length) {
      gtag.pageview(location.pathname, gaTrackingId);
    }
  }, [location, gaTrackingId]);

  useChangeLanguage(locale);

  return (
    <html lang={locale} dir={i18n.dir()} className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        {process.env.NODE_ENV === 'development' || !gaTrackingId ? null : (
          <>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-LYESJ6457Q"></script>
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          </>
        )}
        <Toaster />
        <HoneypotProvider {...honeyProps}>{children}</HoneypotProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);

export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}
